/* h1,
h2,
h3,
h4,
h5,
h6 {
  color: rgba(255, 255, 255, 1);
} */

.bn-onboard-custom.bn-onboard-modal {
  z-index: 2000;
}

/*
.bn-onboard-custom.bn-onboard-modal-content {
  background: #424242 !important;
}

.bn-onboard-prepare-button {
  border-color: #FFE500 !important;
  color: #FFE500 !important;
}

.bn-onboard-custom.bn-onboard-select-info-container > span {
  color: #FFE500 !important;
} */

